import { useEffect, useState } from "react";
import MediumHold1 from "../Holds/MediumHold1";

export default function USBRHold(props: {
  zeroed_position: [number, number, number];
  color: string;
}) {
  const [x, y, z] = props.zeroed_position;
  const holdColor = props.color;

  const [hovered, setHovered] = useState(false);

  useEffect(() => {
    document.body.style.cursor = hovered ? "grab" : "auto";
  }, [hovered]);
  return (
    <>
      <mesh
        onClick={() => {
          window.open("https://www.usbr.gov/");
        }}
        rotation={[Math.PI / 2, 0, 0]}
        position={[x + 0, y + 0, z + 0]}
        onPointerOver={() => setHovered(true)}
        onPointerOut={() => setHovered(false)}
      >
        <MediumHold1 color={holdColor} />
      </mesh>
    </>
  );
}
