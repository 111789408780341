import AboutHold from "../../Scene/About/about_hold";
import "./styles.css";
import "bootstrap/dist/css/bootstrap.min.css";
import CanvasViewer from "../CanvasViewer";
import { useRef } from "react";
import { PerspectiveCamera } from "three";

export default function About() {
  const camera1Ref = useRef(
    new PerspectiveCamera(75, window.innerWidth / window.innerHeight, 0.1, 1000)
  );
  camera1Ref.current.position.set(0, 0, 25);
  return (
    <>
      <div className="max-width-800">
        <div className="initial-fade-in inter d-flex flex-column">
          <div className="extremely-large-row-spacer" />

          <h6 className="semi-bold white-text">ABOUT</h6>
          <div className="large-row-spacer" />

          <small className="opacity-75">
            I'm currently a student at Northeastern University studying computer
            science and cognitive psychology. An aspiring software engineer, I
            am passionate about creating innovative solutions to complex
            problems.
          </small>

          <div className="default-row-spacer" />
          <small className="opacity-75">
            I've been lucky enough to work at NASA, where I achieved a life-long
            dream of working on a project that pushes forward space and
            aeronautic exploration. I also worked at Travelers, where I was able
            to help build an innovative application boosting the overall quality
            of code in the company. Now, I'm working with the Bureau of
            Reclamation as an AI engineer intern, working a model for
            classifying structural damage.
          </small>

          <div className="default-row-spacer" />
          <small className="opacity-75">
            Feel free to reach out! I'll probably be climbing, watching a
            Celtics game, or trying to build something new...
          </small>
          <CanvasViewer
            child={<AboutHold zeroed_position={[0, 0, 0]} color="#ffa500" />}
            cameraRef={camera1Ref}
            baseScrollY={50}
          />
        </div>
      </div>
    </>
  );
}
