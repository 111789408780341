import React from "react";
import logo from "./logo.svg";
import { Routes, Route, Navigate, HashRouter } from "react-router-dom";
import "./App.css";
import Home from "./Portfolio/Home";
import Scene from "./Portfolio/Scene";
import { Analytics } from "@vercel/analytics/react";

function App() {
  return (
    <HashRouter>
      <Analytics />
      <Routes>
        <Route path="/" element={<Home />} />
      </Routes>
      {/* </div> */}
    </HashRouter>
  );
}

export default App;
